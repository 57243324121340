import React, { Component } from 'react';
import { Drawer, Button } from 'antd';

import AlbumForm from '../Forms/Album';
import { newDocument } from '../../api/firebase/firestore';

import './assets/style.css';

class AddAlbum extends Component {
  state = {
    visible: false,
    album: {},
  };

  showDrawer = () => {
    this.setState({
      visible: true,
      album: {
        id: newDocument({ path: 'albums' }).id,
        album: true,
        playlist: false,
        single: false,
      },
    });
  };

  hideDrawer = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible, album } = this.state;

    return (
      <div>
        {this.props.smallButton ? (
          <Button
            primary
            type="plus"
            icon="cloud"
            size="small"
            onClick={this.showDrawer}
          />
        ) : (
          <Button icon="plus" type="primary" onClick={this.showDrawer}>
            Add New Music
          </Button>
        )}

        <Drawer
          title="Add New Music"
          placement="right"
          visible={visible}
          width="100%"
          destroyOnClose
          onClose={this.hideDrawer}
        >
          <AlbumForm
            new
            album={album}
            onDone={this.hideDrawer}
            onCancel={this.hideDrawer}
          />
        </Drawer>
      </div>
    );
  }
}

export default AddAlbum;
