export const buildObjectsTree = docs => {
  const objects = {};
  docs.forEach(doc => {
    objects[doc.id] = { id: doc.id, ...doc.data() };
    return objects;
  });
  return objects;
};

export const buildObject = doc => ({ id: doc.id, ...doc.data() });
