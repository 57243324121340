import { kebabCase, pick } from 'lodash';

export const humanReadableFileSize = bytes => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2) * 1} ${
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  }`;
};

export const dataURLtoBlob = dataUrl => {
  const arr = dataUrl.split(',');

  const mime = arr[0].match(/:(.*?);/)[1];

  const bstr = atob(arr[1]);

  let n = bstr.length;

  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const blobToDataURL = (blob, callback) => {
  const a = new FileReader();
  a.onload = e => {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
};
export const generateSlug = words => kebabCase(words);

export const generateSearchKeys = (data, fields) =>
  Object.keys(pick(data, fields)).map(key => {
    return data[key].toLowerCase();
  });

export const fullName = (firstName, lastName) => `${firstName} ${lastName}`;
