import React, { Component } from 'react';
import {
  InstantSearch,
  Configure,
  connectAutoComplete,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

import { pick, find } from 'lodash';
import { Dropdown } from 'semantic-ui-react';

const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_ID || '';
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_KEY || '';

const searchClient = algoliasearch(ALGOLIA_APP_ID || '', ALGOLIA_API_KEY || '');

class ArtistFilter extends Component {
  state = { hits: [] };

  static getDerivedStateFromProps(props) {
    return {
      hits: props.hits,
    };
  }

  componentDidMount() {
    const {
      value: { id, search },
    } = this.props;

    // Execute initial search to load data.
    if (id) this.props.refine(id);
    if (search) this.props.refine(search);
  }

  handleChange = (e, { value }) => {
    const selected = find(this.state.hits, { objectID: value });

    const artist = {
      id: selected.objectID,
      ...pick(selected, [
        'firstName',
        'lastName',
        'name',
        'nickName',
        'profilePhoto',
      ]),
    };
    if (this.props.onChange) this.props.onChange(artist);
  };

  handleSearchChange = (e, { searchQuery }) => {
    this.props.refine(searchQuery);
  };

  render = () => {
    const { hits, searchQuery, value } = this.props;

    return (
      <Dropdown
        fluid
        search
        selection
        selectOnBlur={false}
        label="Select Artist"
        placeholder="Select Artist"
        searchQuery={searchQuery}
        value={value.id}
        options={hits.map(artist => ({
          key: artist.objectID,
          text: `${artist.name} ${
            artist.nickName ? `(${artist.nickName})` : ''
          }`,
          value: artist.objectID,
          image: { avatar: true, src: artist.profilePhoto },
        }))}
        onChange={this.handleChange}
        onSearchChange={this.handleSearchChange}
      />
    );
  };
}

const ConnectedArtistFilter = connectAutoComplete(ArtistFilter);

// eslint-disable-next-line react/prefer-stateless-function
export default class SearchArtist extends Component {
  render() {
    return (
      <InstantSearch searchClient={searchClient} indexName="artists">
        <ConnectedArtistFilter {...this.props} />
        <Configure hitsPerPage={10} />
      </InstantSearch>
    );
  }
}
