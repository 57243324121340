import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, InputNumber, Button } from 'antd';

import { addArtist, updateArtist } from '../../redux/actions/artists';
import ProfilePictureUploader from '../Upload/ProfilePictureUploader';
import SearchArtist from '../Search/SearchArtist';

class Song extends Component {
  state = {
    data: {
      id: '',
      title: '',
      track: null,
      year: null,
      coverPhoto: '',
      downloadUrl: '',
      artist: {},
      album: '',
      ...this.props.song,
    },
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, data) => {
      if (!err && data && data.id) {
        // New
        if (this.props.new) {
          this.props.addArtist({
            ...data,
          });
          if (this.props.onDone) this.props.onDone();
        }
        // Update
        if (this.props.update) {
          this.props.updateArtist({
            ...data,
          });
          if (this.props.onDone) this.props.onDone();
        }
      }
    });
  };

  validateFields = () => {
    const { form } = this.props;
    form.validateFields((err, data) => {
      if (!err && data && data.id) {
        if (this.props.updatedSong) this.props.updatedSong(data);
        if (this.props.onDone) this.props.onDone();
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    const {
      data: { id, artist, title, track, year, coverPhoto, album, downloadUrl },
    } = this.state;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    getFieldDecorator('id', { initialValue: id });
    getFieldDecorator('downloadUrl', { initialValue: downloadUrl });
    return (
      <Form>
        <Form.Item {...formItemLayout} label="Song cover">
          {getFieldDecorator('coverPhoto', {
            initialValue: coverPhoto,
          })(
            <ProfilePictureUploader
              name={id}
              buttonTitle="Song photo"
              uploadPath="songs"
              cropperTitle="Upload song photo"
            />
          )}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Artist">
          {getFieldDecorator('artist', {
            initialValue: artist,
            rules: [
              {
                required: true,
                message: 'Please input title',
              },
            ],
          })(<SearchArtist />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Track">
          {getFieldDecorator('track', {
            initialValue: track,
            rules: [
              {
                whitespace: true,
                type: 'number',
              },
            ],
          })(<InputNumber />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Song title">
          {getFieldDecorator('title', {
            initialValue: title,
            rules: [
              {
                required: true,
                message: 'Please add song title',
                whitespace: true,
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Album title">
          {getFieldDecorator('album', {
            initialValue: album,
            rules: [
              {
                required: true,
                message: 'Please add track number',
                whitespace: true,
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Year">
          {getFieldDecorator('year', {
            initialValue: year,
            rules: [
              {
                message: 'Please add release year',
                whitespace: true,
                type: 'number',
              },
            ],
          })(<InputNumber />)}
        </Form.Item>

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button
            onClick={e => {
              e.preventDefault();
              const { onCancel } = this.props;
              if (onCancel) onCancel();
            }}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={this.validateFields}>
            Save
          </Button>
        </div>
      </Form>
    );
  }
}

const ArtistForm = Form.create({ name: 'artist_form' })(Song);

export default connect(
  null,
  { addArtist, updateArtist }
)(ArtistForm);
