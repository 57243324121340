import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button, message } from 'antd';
import { withRouter, Link } from 'react-router-dom';

// Import style
import './assets/style.css';

// Import actions
import { signInWithEmailAndPassword } from '../../redux/actions/user';

class SignIn extends Component {
  state = {
    loading: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.signInUser(values);
      }
    });
  };

  signInUser = async ({ email, password }) => {
    try {
      if (!email || !password)
        throw Error('username and password are required');

      await this.props.signInWithEmailAndPassword(email, password);

      const { user } = this.props;

      if (user) {
        console.log(user);
        message.success(`Welcome ${user.displayName || user.email}`);
        const { location, history } = this.props;
        if (location.state && location.state.from) {
          const { pathname } = location.state.from;
          history.replace(pathname);
        } else {
          history.replace('/');
        }
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form" noValidate>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Sign In
          </Button>

          <Link to="/forgot-password">Reset your password</Link>
        </Form.Item>
      </Form>
    );
  }
}

const SignInForm = Form.create({ name: 'sing_in' })(SignIn);

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    { signInWithEmailAndPassword }
  )(SignInForm)
);
