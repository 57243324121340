import React, { Component } from 'react';
import { Drawer, Button } from 'antd';

import ArtistForm from '../Forms/Artist';
import { newDocument } from '../../api/firebase/firestore';

import './assets/style.css';

class AddArtist extends Component {
  state = { visible: false, artist: {} };

  showDrawer = () => {
    this.setState({
      visible: true,
      artist: { id: newDocument({ path: 'artists' }).id },
    });
  };

  hideDrawer = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible, artist } = this.state;
    return (
      <div>
        {this.props.smallButton ? (
          <Button
            primary
            type="plus"
            icon="cloud"
            size="small"
            onClick={this.showDrawer}
          />
        ) : (
          <Button icon="plus" type="primary" onClick={this.showDrawer}>
            Add New Artist
          </Button>
        )}

        <Drawer
          title="Add New Artist"
          placement="right"
          visible={visible}
          width={720}
          destroyOnClose
          onClose={this.hideDrawer}
        >
          <ArtistForm
            new
            artist={artist}
            onDone={this.hideDrawer}
            onCancel={this.hideDrawer}
          />
        </Drawer>
      </div>
    );
  }
}

export default AddArtist;
