import React, { useState, useEffect, forwardRef } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { List } from 'antd';
import { useDispatch } from 'react-redux';
import { updateFeatured } from '../../redux/actions/featured';

import FeaturedItem from './FeaturedItem';

const SortableItem = ({ index, value, onDelete }) => (
  <FeaturedItem index={index} onDelete={onDelete} value={value} />
);

const FeaturedSortableList = SortableContainer(
  ({ items, header, onDelete }) => {
    return (
      <List
        header={header}
        bordered
        size="large"
        dataSource={items}
        renderItem={(item, index) => (
          <SortableItem index={index} onDelete={onDelete} value={item} />
        )}
      />
    );
  }
);

const FeaturedList = forwardRef(
  ({ onChange, listBuilder, header = null }, ref) => {
    const [id, setId] = useState('');
    const [list, setList] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
      if (listBuilder.id) {
        setId(listBuilder.id);
        setList(listBuilder.list);
      }
    }, [listBuilder]);

    const updateList = updatedList => {
      setList(updatedList);

      if (onChange) {
        onChange({ id, list: updatedList });
      }

      // If id is supplied, write to firebase directly.
      if (id) dispatch(updateFeatured(id, { list: updatedList }));
    };

    return (
      <FeaturedSortableList
        ref={ref}
        items={list}
        header={header}
        onDelete={deletedItem => {
          if (deletedItem && deletedItem.id) {
            const updatedList = list.filter(item => item.id !== deletedItem.id);
            updateList(updatedList);
          }
        }}
        onSortEnd={({ oldIndex, newIndex }) => {
          // Re-assigned avoid mutation.
          let updatedList = list;
          updatedList = arrayMove(updatedList, oldIndex, newIndex);
          updateList(updatedList);
        }}
      />
    );
  }
);

export default FeaturedList;
