import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';

import './assets/style.css';

// Import layout components.
import SideBlock from './components/Layout/SideBlock';
import FooterBlock from './components/Layout/FooterBlock';
import HeaderBlock from './components/Layout/HeaderBlock';
import ContentBlock from './components/Layout/ContentBlock';

// Import content component.
import AppContent from './AppContent';

const App = () => (
  <Router>
    <Layout style={{ minHeight: '100vh' }}>
      <SideBlock />
      <Layout>
        <HeaderBlock />
        <ContentBlock>
          <AppContent />
        </ContentBlock>
        <FooterBlock />
      </Layout>
    </Layout>
  </Router>
);

export default App;
