import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  InputNumber,
  Button,
  notification,
  Icon,
  Row,
  Col,
  Radio,
} from 'antd';
import { isEmpty, filter } from 'lodash';

import { addAlbum, updateAlbum } from '../../redux/actions/albums';
import { addSongs } from '../../redux/actions/songs';
import ProfilePictureUploader from '../Upload/ProfilePictureUploader';
import SearchArtist from '../Search/SearchArtist';
import MusicUploader from '../Upload/MusicUploader';
import SongList from '../Songs/SongList';

class Album extends Component {
  state = {
    data: {
      id: '',
      title: '',
      year: null,
      coverPhoto: '',
      artists: [],
      songs: [],
      album: true,
      keys: [],
      type: '',
      ...this.props.album,
    },
  };

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  songsValid = songs => {
    const invalidSongs = filter(songs, song => {
      return (
        !song.title ||
        // !song.album ||
        // !song.year ||
        // !song.track ||
        // !song.coverPhoto ||
        !song.downloadUrl ||
        isEmpty(song.artist)
      );
    });

    if (!isEmpty(invalidSongs))
      this.openNotificationWithIcon(
        'error',
        'Invalid song information',
        'Please complete songs with unknown information'
      );

    return isEmpty(invalidSongs);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;

    form.validateFields((err, data) => {
      if (!err && data && data.id) {
        // New
        if (this.props.new) {
          this.props.addAlbum(data);
        }
        // Update
        if (this.props.update) {
          this.props.updateAlbum(data);
        }

        // Save songs
        if (data.songs) this.props.addSongs(data);

        if (this.props.onDone) this.props.onDone();
      }
    });
  };

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    console.log(keys);
    // const nextKeys = keys.concat(fieldId++);

    const newKey = keys.length || 0;

    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: [...keys, newKey],
    });
  };

  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props;

    const {
      data: { id, title, year, coverPhoto, artists, songs, keys, type },
    } = this.state;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const formItemLayoutWithOutLabel = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    getFieldDecorator('keys', { initialValue: keys });
    const keyList = getFieldValue('keys');
    const formItems = keyList.map(k => (
      <Form.Item {...formItemLayout} label="Artist" required={false} key={k}>
        {getFieldDecorator(`artists[${k}]`, {
          initialValue: artists[k] || {},
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message: 'Please input title',
            },
          ],
        })(<SearchArtist style={{ width: '80%' }} />)}

        {keyList.length > 1 ? (
          <Icon
            style={{ float: 'right' }}
            className="dynamic-delete-button"
            type="minus-circle-o"
            disabled={keyList.length === 1}
            onClick={() => this.remove(k)}
          />
        ) : null}
      </Form.Item>
    ));

    getFieldDecorator('id', { initialValue: id });

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col span={12}>
            <Form.Item {...formItemLayout} label="Cover picture">
              {getFieldDecorator('coverPhoto', {
                initialValue: coverPhoto,
                rules: [
                  {
                    required: true,
                    message: 'Please attach cover picture',
                  },
                ],
              })(
                <ProfilePictureUploader
                  rounded
                  name="cover"
                  buttonTitle="Cover photo"
                  uploadPath={`albums/${id}`}
                  cropperTitle="Upload cover Photo"
                />
              )}
            </Form.Item>
            {formItems}
            <Form.Item
              className="form-hidden-label"
              {...formItemLayoutWithOutLabel}
              label="&nbsp;"
            >
              <Button
                type="dashed"
                onClick={this.add}
                style={{ width: '100%' }}
              >
                <Icon type="plus" /> Add Artist
              </Button>
            </Form.Item>

            <Form.Item {...formItemLayout} label="Title">
              {getFieldDecorator('title', {
                initialValue: title,
                rules: [
                  {
                    required: true,
                    message: 'Please input title',
                    whitespace: true,
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Year">
              {getFieldDecorator('year', {
                initialValue: year,
                rules: [
                  {
                    required: true,
                    message: 'Please input year',
                  },
                ],
              })(<InputNumber min={1800} max={2030} />)}
            </Form.Item>

            <Form.Item label="Type">
              {getFieldDecorator('type', {
                initialValue: type,
                rules: [
                  {
                    required: true,
                    message: 'Please select album type',
                  },
                ],
              })(
                <Radio.Group>
                  <Radio value="album">Album</Radio>
                  <Radio value="playlist">Playlist</Radio>
                  <Radio value="single">Single</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item {...formItemLayout} label="Songs">
              <MusicUploader
                uploadPath={`albums/${id}`}
                onChange={addedSongs => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      songs: [...songs, ...addedSongs],
                    },
                  });
                }}
              />
            </Form.Item>

            <Form.Item label="">
              {getFieldDecorator('songs', {
                initialValue: songs,
                rules: [
                  {
                    required: true,
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (value && value.length && !this.songsValid(value)) {
                        callback(
                          'Please complete songs with unknown information'
                        );
                      }
                      callback();
                    },
                  },
                ],
              })(<SongList />)}
            </Form.Item>
          </Col>
        </Row>

        {/* <Tabs tabPosition="left" style={{ paddingBottom: '40px' }}>
          <TabPane tab="Songs" key="1">
            




          </TabPane>
          <TabPane tab="Details" key="2">
            
          </TabPane>
        </Tabs> */}

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button
            onClick={e => {
              e.preventDefault();
              const { onCancel } = this.props;
              if (onCancel) onCancel();
            }}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    );
  }
}

const ArtistForm = Form.create({ name: 'artist_form' })(Album);

export default connect(null, { addAlbum, updateAlbum, addSongs })(ArtistForm);
