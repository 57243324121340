import { FEATURED_FETCHED, FEATURED_SELECTED } from '../actions/types';

const initialState = {
  selected: {},
  list: {},
  last: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FEATURED_FETCHED:
      return { ...state, ...payload };
    case FEATURED_SELECTED:
      return { ...state, ...payload };
    default:
      return state;
  }
};
