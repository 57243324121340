import {
  ARTISTS_FETCHED,
  ARTIST_SELECTED,
  ARTIST_ADDED,
  ARTIST_UPDATED,
} from '../actions/types';

const initialState = {
  selected: {},
  list: {},
  last: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ARTISTS_FETCHED:
      return {
        ...state,
        list: { ...state.list, ...action.artists.data },
        last: action.artists.lastDocument,
      };
    case ARTIST_SELECTED:
      return { ...state, selected: action.selected };
    case ARTIST_ADDED:
      return {
        ...state,
        list: { ...state.list, [action.artist.id]: action.artist },
      };
    case ARTIST_UPDATED:
      return {
        ...state,
        list: { ...state.list, [action.artist.id]: action.artist },
      };

    default:
      return state;
  }
};
