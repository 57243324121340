import React, { Component } from 'react';
import {
  InstantSearch,
  Configure,
  connectHits,
  connectSearchBox,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

import { Avatar, Table, Icon, Button, Input } from 'antd';
import { omit } from 'lodash';

import SongPlayer from '../Player/SongPlayer';

import './assets/style.css';

const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_ID || '';
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_KEY || '';

const searchClient = algoliasearch(ALGOLIA_APP_ID || '', ALGOLIA_API_KEY || '');

const columns = [
  {
    dataIndex: 'albumCover',
    render: src => <Avatar size="large" src={src} shape="square" />,
  },
  {
    title: 'Songs',
    render: (text, row, index) => (
      <div>
        <h4>{row.title}</h4>
        <div>{row.artist.name}</div>
        <div>{`${row.album} ∙ ${row.year}`}</div>
      </div>
    ),
  },
  {
    dataIndex: 'downloadUrl',
    render: url => <SongPlayer streamUrl={url} preloadType="auto" />,
  },
];

class SongSearch extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
    if (this.props.songsSelected)
      this.props.songsSelected(
        selectedRows.map(row => omit(row, '_highlightResult'))
      );
  };

  render() {
    const { selectedRowKeys } = this.state;
    const { hits } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={hits}
        pagination={false}
        showHeader
        title={() => <CustomSearchBox />}
      />
    );
  }
}

class SongSearchBox extends Component {
  handleSearch = e => {
    const query = e.target.value;
    this.props.refine(query);
  };

  render() {
    return (
      <Input
        size="large"
        placeholder="Search songs"
        prefix={<Icon type="search" className="certain-category-icon" />}
        onChange={this.handleSearch}
        allowClear
      />
    );
  }
}

const CustomSearchBox = connectSearchBox(SongSearchBox);
const CustomHits = connectHits(SongSearch);
class SearchSong extends Component {
  state = { selectedSongs: [] };

  handleSongsSelected = selectedSongs => {
    this.setState({ selectedSongs });
  };

  handleOnchange = () => {
    const { selectedSongs } = this.state;
    const { onChange, onCancel } = this.props;

    if (onChange) onChange(selectedSongs);
    if (onCancel) onCancel();
  };

  render() {
    return (
      <InstantSearch searchClient={searchClient} indexName="songs">
        <Configure hitsPerPage={5} />

        <CustomHits songsSelected={this.handleSongsSelected} />
        <div>
          <div
            style={{
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 0',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button
              onClick={e => {
                e.preventDefault();
                const { onCancel } = this.props;
                if (onCancel) onCancel();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!this.state.selectedSongs.length}
              onClick={this.handleOnchange}
            >
              Select Songs
            </Button>
          </div>
        </div>
      </InstantSearch>
    );
  }
}

export default SearchSong;
