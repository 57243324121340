import React from 'react';
import { Grid, Header, Segment, Container } from 'semantic-ui-react';
import ForgotPasswordForm from '../components/Forms/ForgotPassword';

const ForgotPassword = () => {
  return (
    <Container fluid>
      <Grid
        textAlign="center"
        style={{ height: '100%' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header
            className="login-banner"
            textAlign="center"
            image="/images/banner.png"
          />
          <Segment basic>
            <ForgotPasswordForm />
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;
