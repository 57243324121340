import React, { Component } from 'react';
import { Drawer } from 'antd';
import AlbumForm from '../Forms/Album';

class EditAlbum extends Component {
  state = { visible: false };

  showDrawer = e => {
    if (e) e.preventDefault();
    this.setState({
      visible: true,
    });
  };

  hideDrawer = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible } = this.state;

    return (
      <div>
        {/* eslint-disable-next-line */}
        <a href="#" onClick={this.showDrawer}>
          {this.props.linkText || 'edit'}
        </a>

        <Drawer
          title="Edit Music"
          placement="right"
          visible={visible}
          width="100%"
          destroyOnClose
          onClose={this.hideDrawer}
        >
          <AlbumForm
            title="Edit Music"
            update
            album={this.props.album}
            onDone={this.hideDrawer}
            onCancel={this.hideDrawer}
          />
        </Drawer>
      </div>
    );
  }
}

export default EditAlbum;
