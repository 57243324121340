import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Tooltip, Icon, Button } from 'antd';

import { addArtist, updateArtist } from '../../redux/actions/artists';
import ProfilePictureUploader from '../Upload/ProfilePictureUploader';

class Artist extends Component {
  state = {
    data: {
      id: '',
      firstName: '',
      lastName: '',
      nickName: '',
      bio: '',
      profilePhoto: '',
      ...this.props.artist,
    },
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, data) => {
      if (!err && data && data.id) {
        // New
        if (this.props.new) {
          this.props.addArtist({
            ...data,
          });
          if (this.props.onDone) this.props.onDone();
        }
        // Update
        if (this.props.update) {
          this.props.updateArtist({
            ...data,
          });
          if (this.props.onDone) this.props.onDone();
        }
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    const {
      data: { id, firstName, lastName, nickName, profilePhoto, bio },
    } = this.state;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    getFieldDecorator('id', { initialValue: id });

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item {...formItemLayout} label="Profile picture">
          {getFieldDecorator('profilePhoto', {
            initialValue: profilePhoto,
            rules: [
              {
                required: true,
                message: 'Please attach profile picture',
              },
            ],
          })(
            <ProfilePictureUploader
              rounded
              avatar
              name="profile"
              buttonTitle="Profile photo"
              uploadPath={`artists/${id}`}
              cropperTitle="Upload Artist Photo"
            />
          )}
        </Form.Item>
        <Form.Item {...formItemLayout} label="First name">
          {getFieldDecorator('firstName', {
            initialValue: firstName,
            rules: [
              {
                required: true,
                message: 'Please input first name',
                whitespace: true,
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Last name">
          {getFieldDecorator('lastName', {
            initialValue: lastName,
            rules: [
              {
                required: true,
                message: 'Please input last name',
                whitespace: true,
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              Nickname&nbsp;
              <Tooltip title="Artist's nickname if any?">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator('nickName', {
            initialValue: nickName,
          })(<Input />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Short biography">
          {getFieldDecorator('bio', {
            initialValue: bio,
          })(<Input.TextArea />)}
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button
            onClick={e => {
              e.preventDefault();
              const { onCancel } = this.props;
              if (onCancel) onCancel();
            }}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    );
  }
}

const ArtistForm = Form.create({ name: 'artist_form' })(Artist);

export default connect(
  null,
  { addArtist, updateArtist }
)(ArtistForm);
