import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Avatar, Button, Skeleton, Icon } from 'antd';
import { filter, differenceWith, isEqual } from 'lodash';

import EditArtist from './EditArtist';

import { fetchArtists } from '../../redux/actions/artists';

const count = 10;

class ArtistList extends Component {
  state = {
    initLoading: true,
    loading: true,
    data: [],
    list: [],
    last: null,
    showLoadMore: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      artists: { list, last },
    } = this.props;
    const data = filter(list);
    if (
      prevState.last !== last ||
      differenceWith(prevState.list, data, isEqual).length
    ) {
      this.setState({
        list: data,
        data,
        last,
        initLoading: false,
        loading: false,
        showLoadMore: !!last,
      });
    }
  };

  fetchData = order => {
    const orderBy = order || [{ field: 'firstName', direction: 'asc' }];
    this.props.fetchArtists(count, this.state.last, orderBy);
  };

  refreshData = e => {
    if (e) e.preventDefault();

    this.setState(
      {
        initLoading: true,
        loading: true,
        data: [],
        list: [],
        last: null,
        showLoadMore: true,
      },
      () => {
        this.fetchData();
      }
    );
  };

  onLoadMore = () => {
    const { data } = this.state;
    // Useful to animate next loading placeholders.
    this.setState({
      loading: true,
      list: [
        ...data,
        ...[...new Array(count)].map(() => ({ loading: true, firstName: {} })),
      ],
    });

    this.fetchData();
  };

  render() {
    const { initLoading, loading, showLoadMore, list } = this.state;
    const loadMore =
      !initLoading && !loading && showLoadMore ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={this.onLoadMore}>load more</Button>
        </div>
      ) : null;

    const IconText = ({ type, text }) => (
      <span>
        <Icon type={type} theme="twoTone" style={{ marginRight: 8 }} />
        {text}
      </span>
    );
    const RefreshButton = () => (
      <div align="right">
        {/* eslint-disable-next-line */}
        <a href="#" onClick={this.refreshData}>
          <Icon
            type="sync"
            spin={this.state.loading}
            style={{ marginRight: 8 }}
            theme="outlined"
          />
          Refresh
        </a>
      </div>
    );

    return (
      <div>
        <RefreshButton />
        <List
          className="artists-loadmore-list"
          loading={initLoading}
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={list}
          renderItem={item => (
            <List.Item
              actions={[
                <IconText type="customer-service" text={item.songs || 0} />,
                <IconText type="heart" text={item.likes || 0} />,
                <EditArtist artist={item} />,
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={<Avatar size={64} src={item.profilePhoto} />}
                  title={`${item.firstName} ${item.lastName} ${
                    item.nickName ? `(${item.nickName})` : ''
                  }`}
                  description={item.bio}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  artists: state.artists,
});
export default connect(
  mapStateToProps,
  { fetchArtists }
)(ArtistList);
