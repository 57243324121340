const config = {
  apiKey: 'AIzaSyBg07s1tllG0iHWTEOSuP4OGy5BQesMZ-E',
  authDomain: 'dankira-app.firebaseapp.com',
  databaseURL: 'https://dankira-app.firebaseio.com',
  projectId: 'dankira-app',
  storageBucket: 'dankira-app.appspot.com',
  messagingSenderId: '385006682785',
};

export default config;
