import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

import UserProfileMenu from '../Profile/UserProfileMenu';

const { Header } = Layout;

const HeaderBlock = ({ auth }) => {
  const { authenticated } = auth;
  if (!authenticated) return null;
  return (
    <Header style={{ background: '#fff', padding: '0 20px' }}>
      <Link to="/">
        <Image size="small" src="/images/banner.png" verticalAlign="middle" />
      </Link>

      <div style={{ float: 'right' }}>
        <UserProfileMenu />
      </div>
    </Header>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(HeaderBlock);
