import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';

// Import pages.
// import VerifyEmail from '../Components/Actions/VerifyEmail';
import ResetPassword from '../components/Forms/ResetPassword';

const Action = ({ location, history }) => {
  const selectMode = () => {
    const { search } = location;
    const params = new URLSearchParams(search);

    // Get the action to complete.
    const mode = params.get('mode');

    // Get the one-time code from the query parameter.
    const actionCode = params.get('oobCode');

    // Get the continue Url to distinguish if the user is invited
    const continueUrl = params.get('continueUrl');

    switch (mode) {
      case 'recoverEmail':
        // Display reset password handler and UI.
        return null;
      case 'resetPassword':
        // Display email recovery handler and UI.
        return (
          <ResetPassword actionCode={actionCode} continueUrl={continueUrl} />
        );

      // case 'verifyEmail':
      //   return <VerifyEmail actionCode={actionCode} />;

      default:
        history.replace('/404');
        return null;
    }
  };

  return (
    <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 500 }}>
        <Header
          className="login-banner"
          textAlign="center"
          image="/images/banner.png"
        />
        <Segment>{selectMode()}</Segment>
      </Grid.Column>
    </Grid>
  );
};

export default Action;
