import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterBlock = ({ auth }) => {
  const { authenticated } = auth;
  if (!authenticated) return null;
  return (
    <Footer style={{ textAlign: 'center' }}>
      Dankira ©2019 - Nahom Records, Inc.
    </Footer>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(FooterBlock);
