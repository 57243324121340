import React from 'react';

import { Header, Container, Segment } from 'semantic-ui-react';

import FeaturedWrapper from '../components/Featured';

function Featured() {
  return (
    <Container fluid>
      <Header as="h2" content="Featured" subheader="Manage featurted items" />
      <Segment basic>
        <FeaturedWrapper />
      </Segment>
    </Container>
  );
}

export default Featured;
