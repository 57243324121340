import { message } from 'antd';

import { ALBUMS_FETCHED, ALBUM_ADDED, ALBUM_UPDATED } from './types';

import { getDocuments, setDocument } from '../../api/firebase/firestore';
import { generateSlug } from '../../helpers/utils';

export const albumsFetched = albums => ({
  type: ALBUMS_FETCHED,
  albums,
});

export const albumAdded = album => ({
  type: ALBUM_ADDED,
  album,
});

export const albumUpdated = album => ({
  type: ALBUM_UPDATED,
  album,
});

export const fetchAlbums = (
  conditions,
  limit,
  startAfter,
  orderBy
) => async dispatch => {
  try {
    const albums = await getDocuments({
      path: 'albums',
      conditions,
      limit,
      startAfter,
      orderBy,
    });
    return dispatch(albumsFetched(albums));
  } catch (error) {
    message.error(error.message);
    throw Error(error.message);
  }
};

export const addAlbum = data => async dispatch => {
  let { songs } = data;
  songs = songs.map(song => ({
    albumId: data.id,
    albumCover: data.coverPhoto,
    artist: data.artist,
    ...song,
  }));
  try {
    await setDocument({
      path: `albums/${data.id}`,
      data: {
        ...data,
        songs,
        slug: generateSlug(data.title),
        addedAt: Date.now(),
      },
    });
    message.success(`${data.title} is added successfully.`);

    return dispatch(albumAdded(data));
  } catch (error) {
    message.error(error.message);
    throw Error(error.message);
  }
};

export const updateAlbum = data => async dispatch => {
  let { songs } = data;
  songs = songs.map(song => ({
    albumId: data.id,
    albumCover: data.coverPhoto,
    artist: data.artist,
    ...song,
  }));
  try {
    await setDocument({
      path: `albums/${data.id}`,
      data: {
        ...data,
        songs,
        slug: generateSlug(data.title),
        updatedAt: Date.now(),
      },
    });
    message.success(`${data.title} is updated successfully.`);
    return dispatch(albumUpdated(data));
  } catch (error) {
    message.error(error.message);
    throw Error(error.message);
  }
};
