import { message } from 'antd';

import { ARTISTS_FETCHED, ARTIST_ADDED, ARTIST_UPDATED } from './types';

import {
  getDocuments,
  setDocument,
  updateDocument,
} from '../../api/firebase/firestore';
import { generateSlug, fullName } from '../../helpers/utils';

export const artistsFetched = artists => ({
  type: ARTISTS_FETCHED,
  artists,
});

export const artistAdded = artist => ({
  type: ARTIST_ADDED,
  artist,
});

export const artistUpdated = artist => ({
  type: ARTIST_UPDATED,
  artist,
});

export const searchArtists = async value => {
  try {
    if (!value) return {};
    const artists = await getDocuments({
      path: 'artists',
      orderBy: [{ field: 'key', direction: 'asc' }],
      conditions: [{ field: 'searchKeys', operation: 'array-contains', value }],
    });
    return artists.data;
  } catch (error) {
    message.error(error.message);
    return error;
  }
};

export const fetchArtists = (limit, startAfter, orderBy) => async dispatch => {
  try {
    const artists = await getDocuments({
      path: 'artists',
      limit,
      startAfter,
      orderBy,
    });

    return dispatch(artistsFetched(artists));
  } catch (error) {
    message.error(error.message);
    return error;
  }
};

export const addArtist = data => async dispatch => {
  try {
    await setDocument({
      path: `artists/${data.id}`,
      data: {
        name: fullName(data.firstName, data.lastName),
        slug: generateSlug(`${data.firstName} ${data.lastName}`),
        ...data,
        createdAt: Date.now(),
      },
    });
    message.success(`${data.firstName} ${data.lastName} added successfully.`);

    return dispatch(artistAdded(data));
  } catch (error) {
    message.error(error.message);
    return error;
  }
};

export const updateArtist = data => async dispatch => {
  try {
    await updateDocument({
      path: `artists/${data.id}`,
      data: {
        name: fullName(data.firstName, data.lastName),
        slug: generateSlug(`${data.firstName} ${data.lastName}`),
        ...data,
        updatedAt: Date.now(),
      },
    });
    message.success(`${data.firstName} ${data.lastName} updated successfully.`);
    return dispatch(artistUpdated(data));
  } catch (error) {
    message.error(error.message);
    return error;
  }
};
