import { combineReducers } from 'redux';

/**
 * Add reducers here.
 * e.g.
 * import users from './users';
 * import jobs from './jobs';
 * const rootReducer = combineReducers({
 *  users,
 *  jobs,
 * });
 *
 *  export default rootReducer;
 */

import auth from './auth';
import user from './user';
import artists from './artists';
import albums from './albums';
import featured from './featured';

const rootReducer = combineReducers({
  auth,
  user,
  artists,
  albums,
  featured,
});

export default rootReducer;
