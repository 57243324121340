import React, { Component } from 'react';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
// import Webcam from '@uppy/webcam';
import {
  Button,
  Modal,
  Grid,
  Segment,
  Image,
  Placeholder,
} from 'semantic-ui-react';
import AvatarEditor from 'react-avatar-editor';

// Import API.
import { storage } from '../../api/firebase';

// Import helpers.
import FirebaseCloudStorage from './FirebaseCloudStorage';

// Import components.
import { dataURLtoBlob } from '../../helpers/utils';

// Import style.
import './asset/style.css';

/**
 * Props:
 * rounded: Boolean, Default false, show rounded image corners or not.
 * avatar: Boolean, Default false, show rounded image or not.
 * showPreview: Boolean, Default true, show/hide uploaded image preview.
 * size: string, Default "small", size of preview image.
 * name: string, Default "profile", name of image which is going to be used as file name if
 * blank uuid will be used to generate random name.
 * buttonTitle: string, Default "upload", upload button text.
 * uploadPath: string, Required firebase storage upload path.
 * cropperTitle: string, Default 'Profile Picture Uploader' Cropper window header.
 * onChange: func, on successful upload, function is called with argument containing uploaded image URL.
 * autoProceed: Boolean, Default false, whether to upload of file immediately upon select.
 * maxFileSize: number, Default 30000000, maximum upload size.
 */

class ProfilePictureUploader extends Component {
  constructor(props) {
    super();
    this.state = {
      modalOpen: false,
      imageUrl: '',
      rawImage: null,
    };

    this.initUppy(props);
  }

  handleOpen = e => {
    if (e) e.preventDefault();
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = e => {
    if (e) e.preventDefault();
    this.setState({
      modalOpen: false,
    });
  };

  initUppy = props => {
    const storageRef = storage()
      .ref()
      .child(props.uploadPath);

    this.uppy = new Uppy({
      id: 'uppy1',
      autoProceed: props.autoProceed || false,
      restrictions: {
        maxFileSize: props.maxFileSize || 30000000,
        allowedFileTypes: ['image/*'],
        minNumberOfFiles: 1,
        maxNumberOfFiles: 1,
      },
    })
      .use(FirebaseCloudStorage, {
        storageRef,
        refId: props.name || '',
        rename: true,
      })
      // .use(Webcam, {
      //   onBeforeSnapshot: () => Promise.resolve(),
      //   countdown: false,
      //   modes: ['picture'],
      //   mirror: false,
      //   facingMode: 'user',
      //   locale: {},
      // })
      .on('file-added', result => {
        if (result.source === 'Webcam') {
          return;
        }
        // Set rawImage so that avatar editor is visible with the image loaded.
        if (result.data) this.setState({ rawImage: result.data });
      }) // When individual files are added
      .on('complete', result => {
        if (this.props.onChange) {
          this.props.onChange(result.successful[0].downloadUrl);
          this.setState({ imageUrl: result.successful[0].downloadUrl });
        }

        this.clearPicture();
        this.handleClose();
      });
  };

  clearPicture = () => {
    if (this.uppy) {
      const currentFiles = this.uppy.getFiles();
      currentFiles.forEach(currentFile => this.uppy.removeFile(currentFile.id));
    }
    this.setState({ rawImage: null });
  };

  showCropWindow = () => (
    <Segment placeholder>
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={10}>
            <Segment basic textAlign="center">
              <AvatarEditor
                ref={editor => (this.editor = editor)}
                image={this.state.rawImage}
                width={300}
                height={300}
                border={50}
                scale={1}
                rotate={0}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={6}>
            <Button.Group fluid>
              <Button
                primary
                content="Crop"
                onClick={e => {
                  e.preventDefault();

                  // Generate blob from the editor canvas
                  const blob = dataURLtoBlob(
                    this.editor.getImage().toDataURL()
                  );

                  // Clear original selected file
                  const currentFiles = this.uppy.getFiles();
                  currentFiles.forEach(currentFile =>
                    this.uppy.removeFile(currentFile.id)
                  );

                  // Add cropped file to Uppy
                  this.uppy.addFile({
                    type: blob.type,
                    data: blob,
                    preview: window.URL.createObjectURL(blob),
                  });

                  // Clear rawImage so that clop window disappears.
                  this.setState({ rawImage: null });
                }}
              />
              <Button.Or />
              {this.state.rawImage && (
                <Button
                  content="Clear"
                  onClick={e => {
                    e.preventDefault();
                    this.clearPicture();
                  }}
                />
              )}
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );

  render() {
    return (
      <div>
        <div>
          {this.props.showPreview !== false &&
            (this.props.value || this.state.imageUrl) && (
              <div>
                {!this.props.value && !this.state.imageUrl ? (
                  <Placeholder style={{ height: 150, width: 150 }}>
                    <Placeholder.Image square />
                  </Placeholder>
                ) : (
                  <Image
                    src={this.state.imageUrl || this.props.value}
                    avatar={this.props.avatar || false}
                    rounded={this.props.rounded || false}
                    size={this.props.size || 'small'}
                  />
                )}
              </div>
            )}

          <div>
            <Button
              compact
              positive
              icon="camera"
              type="button"
              content={this.props.buttonTitle || 'Upload'}
              className="round"
              onClick={this.handleOpen}
            />
          </div>
        </div>
        <Modal
          closeIcon
          open={this.state.modalOpen}
          size="small"
          header={this.props.cropperTitle || 'Profile Picture Uploader'}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={() => {
            if (this.uppy) {
              this.uppy.reset();
            }
            this.setState({ modalOpen: false });
            this.clearPicture();
          }}
          content={
            this.state.rawImage ? (
              this.showCropWindow()
            ) : (
              <Dashboard
                uppy={this.uppy}
                // plugins={['Webcam']}
                hideProgressAfterFinish
                inline
                proudlyDisplayPoweredByUppy={false}
                onRequestClose={this.handleClose}
              />
            )
          }
        />
      </div>
    );
  }
}

export default ProfilePictureUploader;
