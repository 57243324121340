import React from 'react';
import { Header, Container, Segment } from 'semantic-ui-react';
import { Row, Col } from 'antd';
import AddAlbum from '../components/Albums/AddAlbum';
import MusicList from '../components/Music/MusicList';

const Music = () => {
  return (
    <Container fluid>
      <Header as="h2" content="Music" subheader="Manage all music" />
      <Segment basic textAlign="right" size="mini">
        <AddAlbum />
      </Segment>
      <Segment basic>
        <Row gutter={24}>
          <Col className="music-row" span={24}>
            <MusicList title="Albums" type="album" />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="music-row" span={12}>
            <MusicList title="Singles" type="single" />
          </Col>
          <Col className="music-row" span={12}>
            <MusicList title="Playlists" type="playlist" />
          </Col>
        </Row>
      </Segment>
    </Container>
  );
};

export default Music;
