import React from 'react';
import { PlayButton, Timer, Progress } from 'react-soundplayer/components';
import { withCustomAudio } from 'react-soundplayer/addons';
import { Row, Col } from 'antd';

import 'react-soundplayer/styles/buttons.css';
import 'react-soundplayer/styles/cover.css';
import 'react-soundplayer/styles/icons.css';
import 'react-soundplayer/styles/progress.css';
import 'react-soundplayer/styles/volume.css';

const SongPlayer = withCustomAudio(props => {
  const { currentTime, duration } = props;
  return (
    <div>
      <Row type="flex" align="middle" justify="start">
        <Col>
          <PlayButton style={{ padding: '10px 0' }} {...props} />
        </Col>
        <Col>
          <Timer style={{ paddingLeft: '10px' }} {...props} />
        </Col>
      </Row>
      <Row type="flex">
        <Progress value={(currentTime / duration) * 100 || 0} {...props} />
      </Row>
    </div>
  );
});

export default SongPlayer;
