import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';

// Import style
import './assets/style.css';

// Import actions
import { resetPassword } from '../../redux/actions/user';

class ForgotPassword extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, data) => {
      if (!err) {
        this.resetPassword(data);
      }
    });
  };

  resetPassword = async ({ email }) => {
    try {
      if (!email) throw Error('your email is required');
      await this.props.resetPassword(email);
      message.success(`A reset link instruction is set to your email ${email}`);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form" noValidate>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Send Reset Instruction
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const ForgotPasswordForm = Form.create({ name: 'forgot_password' })(
  ForgotPassword
);

export default withRouter(
  connect(
    null,
    { resetPassword }
  )(ForgotPasswordForm)
);
