import React from 'react';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';

import { List, Icon, Avatar, Button, Popconfirm } from 'antd';

const DragHandle = sortableHandle(() => (
  <Icon
    style={{ fontSize: '20px', color: '#08c' }}
    type="vertical-align-middle"
  />
));

const processDescription = value => {
  switch (value.type) {
    case 'artist':
      return 'Artist';
    case 'album':
      return `Album · ${value.artists || ''} · ${value.year || ''}`;
    case 'song':
      return `Song · ${value.artists || ''} · ${value.year || ''}`;
    default:
      break;
  }
};

const FeaturedItem = SortableElement(({ value, onDelete = null }) => (
  <List.Item
    // zIndex if for when it's used on a drawer.
    style={{ zIndex: 9999 }}
    actions={[
      <Popconfirm
        placement="left"
        title={`Are you sure you want to delete this item?`}
        onConfirm={() => {
          if (onDelete) onDelete(value);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button type="link">Remove</Button>
      </Popconfirm>,
      <DragHandle />,
    ]}
  >
    <List.Item.Meta
      avatar={
        <Avatar
          shape={value.type === 'artist' ? 'circle' : 'square'}
          size={64}
          src={value.image}
        />
      }
      title={value.title}
      description={processDescription(value)}
    />
  </List.Item>
));

export default FeaturedItem;
