/* eslint-disable no-script-url */
import React, { Component } from 'react';
import { Table, Avatar } from 'antd';
import { isEmpty, orderBy } from 'lodash';

import SongPlayer from '../Player/SongPlayer';

import './assets/style.css';
import EditSong from './EditSong';

// const pagination = { position: 'top' };

class SongList extends Component {
  renderTable = () => {
    const { songs, value } = this.props;

    const songList = songs || value || [];

    const columns = [
      {
        dataIndex: 'coverPhoto',
        key: 'coverPhoto',
        render: image => <Avatar shape="square" src={image} />,
      },
      {
        dataIndex: 'track',
        key: 'track',
      },
      {
        title: 'Songs',
        render: (text, row) => (
          <div>
            <h4>{isEmpty(row.title) ? 'Unknown song' : row.title}</h4>
            <div>
              {isEmpty(row.artist) ? 'Unknown artist' : row.artist.name}
            </div>
            <div>{`${
              isEmpty(row.album) ? 'Unknown album' : row.album
            } ∙ ${row.year || 'Unknown year'}`}</div>
          </div>
        ),
      },
      {
        dataIndex: 'downloadUrl',
        key: 'downloadUrl',
        render: url => <SongPlayer streamUrl={url} preloadType="auto" />,
      },
      {
        title: 'Action',
        key: 'operation',
        width: 100,
        render: (text, row) => (
          <EditSong
            updatedSong={song => {
              const currentSongs = songList;
              if (!song || !currentSongs || !currentSongs.length) return;

              const songIndex = currentSongs.findIndex(
                item => item.id === song.id
              );

              if (songIndex !== -1) {
                // Replace the item by index.
                currentSongs.splice(songIndex, 1, song);
              }

              if (this.props.onChange) {
                this.props.onChange(currentSongs);
              }
            }}
            song={row}
          />
        ),
      },
    ];

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={orderBy(songList, 'track', 'asc')}
        showHeader={false}
        pagination={false}
      />
    );
  };

  render = () => {
    return this.renderTable();
  };
}

export default SongList;
