import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Empty, message, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Import actions.
import { verifyResetCode, confirmPassword } from '../../redux/actions/user';

class ResetPassword extends Component {
  state = {
    confirmDirty: false,
    verified: false,
    verifying: false,
    errors: {},
  };

  componentDidMount = async () => {
    try {
      this.setState({ verifying: true });
      const email = await this.props.verifyResetCode(this.props.actionCode);
      this.setState({
        data: { ...this.state.data, email },
        verifying: false,
        verified: true,
      });
    } catch (error) {
      this.setState({
        verifying: false,
        verified: false,
        errors: { verify: error.message },
      });

      message.error(error.message);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, data) => {
      if (!err) {
        const { actionCode } = this.props;
        const { password } = data;

        if (isEmpty(actionCode))
          message.error('Invalid action code please resend reset email again.');

        try {
          await this.props.confirmPassword(actionCode, password);

          message.success('Password reset successfully');

          setTimeout(() => {
            const { history } = this.props;

            message.info('Redirecting in 3 seconds');

            history.replace('/signin');
          }, 3000);
        } catch (error) {
          message.error(error.message);
        }
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { verifying, verified, errors } = this.state;
    const { getFieldDecorator } = this.props.form;
    return !verified ? (
      <div>
        {!verifying && (
          <Empty
            image={<Icon type="heart" theme="twoTone" twoToneColor="#eb2f96" />}
            description={
              <span>
                <ul>
                  {Object.keys(errors).map(key => (
                    <li key={key}>{errors[key]}</li>
                  ))}
                </ul>
              </span>
            }
          />
        )}
        <Link to="/forgot-password">Resend email again?</Link>
      </div>
    ) : (
      <Form onSubmit={this.handleSubmit} className="login-form" noValidate>
        <Form.Item label="Password">
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Please input your password!',
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input type="password" />)}
        </Form.Item>
        <Form.Item label="Confirm Password">
          {getFieldDecorator('confirmPassword', {
            rules: [
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input type="password" onBlur={this.handleConfirmBlur} />)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Reset Password
          </Button>

          <Link to="/forgot-password">Resend email again?</Link>
        </Form.Item>
      </Form>
    );
  }
}

const ResetPasswordForm = Form.create({ name: 'reset_password' })(
  ResetPassword
);

export default withRouter(
  connect(
    null,
    { verifyResetCode, confirmPassword }
  )(ResetPasswordForm)
);
