import { auth } from '.';

export const authOnListen = (next, error, complete) =>
  auth().onAuthStateChanged(user => next(user || {}), error, complete);

export const signInWithEmail = (email, password) =>
  auth()
    .signInWithEmailAndPassword(email, password)
    .then(user => user);

export const signOutUser = () => auth().signOut();

export const signUpWithEmail = ({ email, password }) =>
  auth()
    .createUserWithEmailAndPassword(email, password)
    .then(user => user);

export const verifyPasswordResetCode = actionCode =>
  auth().verifyPasswordResetCode(actionCode);

export const confirmPasswordReset = (actionCode, newPassword) =>
  auth().confirmPasswordReset(actionCode, newPassword);

export const sendPasswordResetEmail = (email, actionCodeSettings = null) =>
  auth().sendPasswordResetEmail(email, actionCodeSettings);

export const userForceTokenRefresh = () => auth().currentUser.getIdToken(true);

export const sendEmailVerificationLink = () =>
  auth().currentUser.sendEmailVerification();

export const verifyEmail = (actionCode, continueUrl = '') =>
  auth().applyActionCode(actionCode);

export const checkActionCode = actionCode => auth().checkActionCode(actionCode);

export const getCurrentUser = () => auth().currentUser;

export const isUserEmailVerified = () => {
  if (auth().currentUser && auth().currentUser.emailVerified) return true;
  return false;
};
