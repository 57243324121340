import React, { Component } from 'react';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import { Button, Modal, Segment } from 'semantic-ui-react';
import { omit } from 'lodash';
import mm from 'musicmetadata';
import moment from 'moment';

import { storage } from '../../api/firebase';
import FirebaseCloudStorage from './FirebaseCloudStorage';
import { newDocument } from '../../api/firebase/firestore';
import SearchSong from '../Search/SearchSong';

class MusicUploader extends Component {
  constructor(props) {
    super();
    this.state = {
      uploadModalOpen: false,
      selectModalOpen: false,
      songs: {},
    };

    this.initUppy(props);
  }

  openUploadModal = e => {
    if (e) e.preventDefault();
    this.setState({
      uploadModalOpen: true,
    });
  };

  closeUploadModal = e => {
    if (e) e.preventDefault();
    this.setState({
      uploadModalOpen: false,
    });
  };

  initUppy = props => {
    const storageRef = storage()
      .ref()
      .child(props.uploadPath);

    this.uppy = new Uppy({
      id: 'uppy1',
      autoProceed: props.autoProceed || false,
      restrictions: {
        maxFileSize: props.maxFileSize || 30000000,
        allowedFileTypes: ['audio/*'],
        minNumberOfFiles: 1,
      },
    })
      .use(FirebaseCloudStorage, {
        storageRef,
        refId: props.uniqueId || '',
        rename: false,
      })
      .on('file-added', result => {
        const { data, meta, size, id } = result;
        const newSong = newDocument({ path: 'songs' });
        mm(data, async (err, tags) => {
          if (err) {
            console.log(err);
            return;
          }
          const {
            title,
            album,
            year,
            picture,
            artist,
            track: { no },
          } = tags;

          const songYear = year
            ? Number(moment(year, 'YYYY').format('YYYY'))
            : null;

          const coverPhoto =
            (await this.uploadSongPicture(picture, newSong.id)) || '';

          if (props.albumInfo) props.albumInfo(album, songYear, coverPhoto);

          this.setState({
            songs: {
              ...this.state.songs,
              [id]: {
                id: newSong.id,
                title,
                album,
                year: songYear,
                track: no ? Number(no) : null,
                artist: { name: artist && artist.length ? artist[0] : '' },
                coverPhoto,
                meta,
                size,
              },
            },
          });
        });
      })
      .on('complete', async result => {
        const { successful } = result;
        const { songs } = this.state;

        const uploadedSongs = successful.map(song => {
          const { id, downloadUrl, meta, size } = song;
          return { ...songs[id], downloadUrl, meta, size };
        });

        if (props.onChange) props.onChange(uploadedSongs);
        this.uppy.reset();
        this.closeUploadModal();
      })
      .on('file-removed', ({ id }) => {
        const { songs } = this.state;
        this.setState({
          songs: omit(songs, id),
        });
      });
  };

  uploadSongPicture = async (pictures, songId) => {
    if (!songId) return;
    const placeholder =
      'https://firebasestorage.googleapis.com/v0/b/dankira-app.appspot.com/o/music.jpg?alt=media&token=8795aae1-580e-4e36-98f6-91be95aa3263';
    if (!pictures || !pictures.length) return placeholder;

    const byteArray = pictures[0] && pictures[0].data ? pictures[0].data : null;
    if (byteArray) {
      const uploadTask = storage()
        .ref(`songs/${songId}.jpg`)
        .put(byteArray, {
          contentType: 'image/jpeg',
        });
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        uploadTask.on(
          storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          null,
          error => {
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
              resolve(downloadURL);
            } catch (error) {
              console.log(error);
            }
          }
        );
      });
    }
    return placeholder;
  };

  prepareSongs = results => {
    return Promise.all(
      results.map(result => {
        const { downloadUrl, meta, size } = result;
        return new Promise((resolve, reject) => {
          const songId = newDocument({ path: 'songs' }).id;
          mm(result.data, (err, tags) => {
            if (!err) {
              const {
                album,
                title,
                track: { no },
                year,
              } = tags;
              resolve({
                id: songId,
                track: no,
                title,
                album,
                year: Number(year),
                size,
                downloadUrl,
                meta,
              });
            } else {
              reject(err);
            }
          });
        });
      })
    );
  };

  openSelectModal = e => {
    if (e) e.preventDefault();
    this.setState({
      selectModalOpen: true,
    });
  };

  closeSelectModal = () => {
    this.setState({
      selectModalOpen: false,
    });
  };

  openUploadModal = e => {
    if (e) e.preventDefault();
    this.setState({
      uploadModalOpen: true,
    });
  };

  closeUploadModal = () => {
    this.setState({
      uploadModalOpen: false,
    });
  };

  render() {
    return (
      <div>
        <Button.Group>
          <Button
            compact
            positive
            icon="cloud upload"
            type="button"
            content="Upload new"
            className="round"
            onClick={this.openUploadModal}
          />
          <Button.Or text="or" />
          <Button
            compact
            primary
            icon="search"
            type="button"
            content="Select existing"
            className="round"
            onClick={this.openSelectModal}
          />
        </Button.Group>
        <Modal
          closeIcon
          open={this.state.uploadModalOpen}
          size="small"
          header="Upload songs"
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={() => {
            if (this.uppy) {
              this.uppy.reset();
            }
            this.closeUploadModal();
          }}
          content={
            <Dashboard
              uppy={this.uppy}
              hideProgressAfterFinish
              inline
              proudlyDisplayPoweredByUppy={false}
              onRequestClose={this.closeUploadModal}
            />
          }
        />
        <Modal
          closeIcon
          open={this.state.selectModalOpen}
          size="small"
          header="Select songs"
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={() => {
            if (this.uppy) {
              this.uppy.reset();
            }
            this.closeSelectModal();
          }}
          content={
            <Segment basic>
              <SearchSong
                onChange={songs => {
                  if (this.props.onChange) this.props.onChange(songs);
                }}
                onCancel={this.closeSelectModal}
              />
            </Segment>
          }
        />
      </div>
    );
  }
}

export default MusicUploader;
