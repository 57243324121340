import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Skeleton, Icon, Spin, message } from 'antd';
import { Image } from 'semantic-ui-react';
import { filter } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';

import EditAlbum from '../Albums/EditAlbum';

import { fetchAlbums } from '../../redux/actions/albums';
import { getDocuments } from '../../api/firebase/firestore';

const count = 10;

const renderAlbumDetails = item => {
  const allArtists = item.artists && item.artists.map(artist => artist.name);

  const mainArtist = allArtists && allArtists.length ? allArtists[0] : null;

  const otherArtists =
    allArtists && allArtists.length
      ? allArtists.splice(1, allArtists.length - 1).join(', ')
      : null;

  const { year } = item;

  return (
    <div>
      <div>{`${mainArtist} ∙ ${year} `}</div>
      <div>
        <em>Other Artists: {otherArtists}</em>
      </div>
    </div>
  );
};

class AlbumsList extends Component {
  state = {
    initLoading: false,
    loading: false,
    list: [],
    last: null,
    showLoadMore: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async order => {
    const { type } = this.props;
    const { last } = this.state;

    const orderBy = order || [{ field: 'year', direction: 'desc' }];
    let conditions = [];

    if (type)
      conditions = [
        ...conditions,
        { field: 'type', operation: '==', value: type },
      ];

    try {
      const albums = await getDocuments({
        path: 'albums',
        conditions,
        count,
        last,
        orderBy,
      });

      this.setState({
        list: filter(albums.data),
        last: albums.lastDocument,
        initLoading: false,
        loading: false,
        showLoadMore: !!last,
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  refreshData = e => {
    if (e) e.preventDefault();

    this.setState(
      {
        initLoading: true,
        list: [],
        last: null,
        showLoadMore: true,
      },
      () => {
        this.fetchData();
      }
    );
  };

  onLoadMore = () => {
    this.fetchData();
  };

  render() {
    const { initLoading, loading, showLoadMore, list } = this.state;

    const RefreshButton = () => (
      <div align="right">
        {/* eslint-disable-next-line */}
        <a href="#" onClick={this.refreshData}>
          <Icon
            type="sync"
            spin={this.state.loading}
            style={{ marginRight: 8 }}
            theme="outlined"
          />
          Refresh
        </a>
      </div>
    );

    return (
      <div>
        <RefreshButton />
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={this.onLoadMore}
          hasMore={!initLoading && !loading && showLoadMore}
        >
          <List
            className="albums-loadmore-list"
            loading={initLoading}
            itemLayout="horizontal"
            size="small"
            bordered
            header={<div>{this.props.title || 'Music'} </div>}
            dataSource={list}
            renderItem={item => (
              <List.Item key={item.id} actions={[<EditAlbum album={item} />]}>
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={<Image rounded size="tiny" src={item.coverPhoto} />}
                    title={item.title}
                    description={renderAlbumDetails(item)}
                  />
                </Skeleton>
              </List.Item>
            )}
          >
            {loading && showLoadMore && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  albums: state.albums,
});
export default connect(
  mapStateToProps,
  { fetchAlbums }
)(AlbumsList);
