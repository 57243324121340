import React, { useState, useEffect } from 'react';
import { Drawer, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import Featured from '../Forms/Featured';

import { featuredSelected } from '../../redux/actions/featured';

import './assets/style.css';

function AddFeatured({ smallButton }) {
  const [visible, setVisible] = useState(false);
  // const [featured, setFeatured] = useState({});
  const selected = useSelector(store => store.featured.selected);
  const dispatch = useDispatch();

  // Opens the Drawer if there's a selected item.
  useEffect(() => {
    if (selected && selected.id) {
      // setFeatured(selected);
      setVisible(true);
    }
  }, [selected, visible]);

  return (
    <div>
      {smallButton ? (
        <Button
          primary
          icon="plus"
          size="small"
          onClick={() => setVisible(true)}
        />
      ) : (
        <Button icon="plus" type="primary" onClick={() => setVisible(true)}>
          Add Featured
        </Button>
      )}

      <Drawer
        title="Add Featured Category"
        placement="right"
        visible={visible}
        width={720}
        destroyOnClose
        onClose={() => {
          // Clear Selection.
          dispatch(featuredSelected({ selected: {} }));
          setVisible(false);
        }}
      >
        <Featured
          new
          data={selected}
          onDone={() => setVisible(false)}
          onCancel={() => setVisible(false)}
        />
      </Drawer>
    </div>
  );
}

export default AddFeatured;
