import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dimmer } from 'semantic-ui-react';
import { Spin } from 'antd';

const GuestRoute = ({
  auth,
  authenticatedRedirect,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      auth.authenticated ? (
        <Redirect to={authenticatedRedirect || '/'} />
      ) : auth.authenticating ? (
        <Dimmer page active inverted>
          <Spin size="large" />
        </Dimmer>
      ) : (
        <Component {...props} />
      )
    }
  />
);
const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(GuestRoute);
