import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import Avatar from 'react-avatar';

// Import actions
import { signOut } from '../../redux/actions/user';

const UserProfileMenu = ({ user, history, signOut, viewport = 'desktop' }) => {
  if (!user) return null;

  const handleChange = (e, { value }) => {
    switch (value) {
      case 'user':
        history.push('/profile');
        break;
      case 'settings':
        history.push('/settings');
        break;
      case 'sign-out':
        signOut();
        break;
      default:
        break;
    }
  };

  const trigger = (
    <span>
      <Avatar
        style={{ verticalAlign: 'none' }}
        email={!user.photoURL ? user.email : ''}
        name={user.email}
        round
        size={35}
        className="left floated"
        src={user.photoURL}
      />
      <span>
        &nbsp;&nbsp;
        {user.displayName || user.email}
      </span>
    </span>
  );

  const options = [
    { key: 'user', value: 'user', text: 'Account', icon: 'user' },
    { key: 'settings', value: 'settings', text: 'Settings', icon: 'settings' },
    { key: 'sign-out', value: 'sign-out', text: 'Sign Out', icon: 'sign out' },
  ];

  const userMenu =
    viewport === 'mobile' ? (
      <Dropdown
        trigger={trigger}
        options={options}
        selectOnBlur={false}
        onChange={handleChange}
      />
    ) : (
      <Dropdown
        item
        trigger={trigger}
        options={options}
        selectOnBlur={false}
        onChange={handleChange}
      />
    );

  return userMenu;
};

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    { signOut }
  )(UserProfileMenu)
);
