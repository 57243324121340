import { message } from 'antd';

import {
  authOnListen,
  signInWithEmail,
  signOutUser,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from '../../api/firebase/account';

import {
  USER_SIGNING_OUT,
  USER_SIGNED_OUT,
  USER_AUTHENTICATING,
  USER_AUTHENTICATION_FAILED,
  USER_AUTHENTICATED,
  USER_PROFILE_FETCHED,
  USER_SIGNED_IN,
  USER_PASSWORD_RESET_CONFIRMED,
  USER_PASSWORD_RESET_SENT,
  USER_RESET_CODE_VERIFIED,
} from './types';

export const userSignedIn = user => ({
  type: USER_SIGNED_IN,
  user,
});

export const userSigningOut = () => ({
  type: USER_SIGNING_OUT,
});

export const userSignedOut = () => ({
  type: USER_SIGNED_OUT,
});

export const userAuthenticating = () => ({
  type: USER_AUTHENTICATING,
});

export const userAuthenticationFailed = () => ({
  type: USER_AUTHENTICATION_FAILED,
});

export const userAuthenticated = () => ({
  type: USER_AUTHENTICATED,
});

export const userProfileFetched = user => ({
  type: USER_PROFILE_FETCHED,
  user,
});

export const passwordResetConfirmed = () => ({
  type: USER_PASSWORD_RESET_CONFIRMED,
});

export const resetPasswordSent = () => ({
  type: USER_PASSWORD_RESET_SENT,
});

export const resetCodeVerified = () => ({
  type: USER_RESET_CODE_VERIFIED,
});

export const signOut = () => async dispatch => {
  try {
    dispatch(userSigningOut());
    await signOutUser();
    return dispatch(userSignedOut());
  } catch (error) {
    message.error(error.message);
    throw Error(error.message);
  }
};

export const addAuthListener = () => dispatch => {
  dispatch(userAuthenticating());
  return authOnListen(
    user => {
      if (user && user.uid) {
        dispatch(userSignedIn(user));
        dispatch(userAuthenticated());
      } else {
        dispatch(userAuthenticationFailed());
      }
    },
    error => {
      message.error(error.message);
      throw Error(error.message);
    }
  );
};

export const signInWithEmailAndPassword = (
  email,
  password
) => async dispatch => {
  try {
    await signInWithEmail(email, password);
    return dispatch(userAuthenticated());
  } catch (error) {
    message.error(error.message);
    throw Error(error.message);
  }
};

export const resetPassword = (
  email,
  actionCodeSettings = null
) => async dispatch => {
  try {
    await sendPasswordResetEmail(email, actionCodeSettings);
    return dispatch(resetPasswordSent());
  } catch (error) {
    message.error(error.message);
    throw Error(error.message);
  }
};

export const verifyResetCode = actionCode => async dispatch => {
  try {
    const email = await verifyPasswordResetCode(actionCode);
    dispatch(resetCodeVerified());
    return email;
  } catch (error) {
    message.error(error.message);
    throw Error(error.message);
  }
};

export const confirmPassword = (actionCode, password) => async dispatch => {
  try {
    confirmPasswordReset(actionCode, password);
    return dispatch(passwordResetConfirmed());
  } catch (error) {
    message.error(error.message);
    throw Error(error.message);
  }
};
