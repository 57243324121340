import React from 'react';
import { connect } from 'react-redux';
import { Card, Button, Segment, Image } from 'semantic-ui-react';

// Import actions
import { signOut } from '../../redux/actions/user';

const UserInfo = ({ user, signOut }) => {
  return (
    <Segment basic>
      <Card>
        <Image
          src={
            user.photoURL ||
            'http://laurauinteriordesign.com/wp-content/uploads/2018/03/avatar-placeholder.png'
          }
        />
        <Card.Content>
          <Card.Header>{user.displayName || user.email}</Card.Header>
        </Card.Content>
        <Card.Content extra>
          <Button content="Sign Out" onClick={() => signOut()} />
        </Card.Content>
      </Card>
    </Segment>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  { signOut }
)(UserInfo);
