import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Dimmer } from 'semantic-ui-react';
import { Spin } from 'antd';

const RestrictedRoute = ({
  auth,
  user,
  permissions,
  routePermission,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        auth.authenticated ? (
          <Component {...props} />
        ) : auth.authenticating ? (
          <Dimmer page active inverted>
            <Spin size="large" />
          </Dimmer>
        ) : (
          <Redirect
            to={{
              ...props,
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  permissions: state.permissions,
});

export default connect(mapStateToProps)(RestrictedRoute);
