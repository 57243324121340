import {
  FEATURED_FETCHED,
  FEATURED_UPDATED,
  FEATURED_ADDED,
  FEATURED_SELECTED,
  FEATURED_DELETED,
} from './types';
import {
  getDocuments,
  setDocument,
  startCollectionListener,
  addDocument,
  deleteDocument,
} from '../../api/firebase/firestore';

export const featuredFetched = payload => ({
  type: FEATURED_FETCHED,
  payload,
});

export const featuredSelected = payload => ({
  type: FEATURED_SELECTED,
  payload,
});

export const featuredAdded = () => ({
  type: FEATURED_ADDED,
});

export const featuredUpdated = () => ({
  type: FEATURED_UPDATED,
});

export const featureDeleted = () => ({
  type: FEATURED_DELETED,
});

export const fetchFeatured = () => async dispatch => {
  const { data, lastDocument } = await getDocuments({
    path: 'featured',
    conditions: [{ field: 'enabled', operator: '==', value: true }],
  });
  dispatch(featuredFetched({ list: data, last: lastDocument }));
};

export const addFeatured = document => async dispatch => {
  await addDocument({
    path: 'featured',
    data: document,
  });
  dispatch(featuredAdded());
};

export const updateFeatured = (id, document) => async dispatch => {
  await setDocument({
    path: `featured/${id}`,
    data: document,
  });
  dispatch(featuredUpdated());
};

export const deleteFeatured = id => async dispatch => {
  await deleteDocument({
    path: `featured/${id}`,
  });
  dispatch(featureDeleted());
};

export const subscribeToFeatured = () => dispatch =>
  startCollectionListener(
    {
      path: 'featured',
      conditions: [{ field: 'enabled', operator: '==', value: true }],
    },
    data => {
      if (data) dispatch(featuredFetched({ list: data }));
    },
    error => console.log(error.message)
  );
