import {
  USER_AUTHENTICATING,
  USER_AUTHENTICATED,
  USER_AUTHENTICATION_FAILED,
  USER_SIGNING_OUT,
} from '../actions/types';

const initialState = {
  authenticating: true,
  signingOut: false,
  authenticated: false,
  failed: false,
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_AUTHENTICATING:
      return {
        authenticating: true,
        authenticated: false,
        failed: false,
        signingOut: false,
        error: {},
      };
    case USER_AUTHENTICATED:
      return {
        authenticated: true,
        authenticating: false,
        failed: false,
        signingOut: false,
        error: {},
      };
    case USER_AUTHENTICATION_FAILED:
      return {
        failed: true,
        authenticated: false,
        authenticating: false,
        signingOut: false,
        error: action.error,
      };
    case USER_SIGNING_OUT:
      return {
        signingOut: true,
        failed: true,
        authenticated: false,
        authenticating: false,
        error: {},
      };
    default:
      return state;
  }
};
