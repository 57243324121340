import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Switch } from 'antd';

import FeaturedList from '../Featured/FeaturedList';
import GlobalSearch from '../Search/GlobalSearch';

import {
  featuredSelected,
  updateFeatured,
  addFeatured,
} from '../../redux/actions/featured';

const Featured = ({
  onCancel,
  data,
  form: { getFieldDecorator, validateFields },
}) => {
  const [saving, setSaving] = useState(false);
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [list, setList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data.id) setId(data.id || '');
    if (data.title) setTitle(data.title || '');
    if (data.enabled) setEnabled(data.enabled);
    if (data.list) setList(data.list || []);
  }, [data]);

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields(async (err, data) => {
      if (!err && data) {
        processSubmit(data);
      }
    });
  };

  const processSubmit = async ({ id, list: { list }, ...rest }) => {
    setSaving(true);
    // Update
    if (id) await dispatch(updateFeatured(id, { ...rest, list }));
    // New
    else await dispatch(addFeatured({ ...rest, list }));
    setSaving(false);

    // Save and close
    // Clear Selection.
    dispatch(featuredSelected({ selected: {} }));

    if (onCancel) onCancel();
  };

  // Id is is not visible
  getFieldDecorator('id', { initialValue: id });

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Form.Item {...formItemLayout} label="Title">
        {getFieldDecorator('title', {
          initialValue: title,
          rules: [
            {
              required: true,
              message: 'Please input title',
              whitespace: true,
            },
          ],
        })(<Input />)}
      </Form.Item>

      <Form.Item {...formItemLayout} label="Enabled">
        {getFieldDecorator('enabled', {
          initialValue: enabled,
          valuePropName: 'checked',
        })(<Switch />)}
      </Form.Item>

      <Form.Item>
        <GlobalSearch
          onChange={searchSelected => {
            if (searchSelected) setList([...list, searchSelected]);
          }}
        />
      </Form.Item>

      <Form.Item label="Items in category">
        {getFieldDecorator('list', {
          initialValue: { id, list },
          valuePropName: 'listBuilder',
        })(<FeaturedList />)}
      </Form.Item>

      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button
          onClick={() => {
            // Clear Selection.
            dispatch(featuredSelected({ selected: {} }));

            if (onCancel) onCancel();
          }}
          style={{ marginRight: 8 }}
        >
          Cancel
        </Button>
        {id && (
          <Button disabled={saving} type="danger" style={{ marginRight: 8 }}>
            Remove
          </Button>
        )}

        <Button
          loading={saving}
          htmlType="submit"
          type="primary"
          style={{ marginRight: 8 }}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

const FeaturedForm = Form.create({ name: 'featured_form' })(Featured);

export default FeaturedForm;
