// Import pages
import Artists from '../pages/Artists';
import Dashboard from '../pages/Dashboard';
import SignIn from '../pages/SignIn';
import Profile from '../pages/Profile';
import Music from '../pages/Music';
import ForgotPassword from '../pages/ForgotPassword';
import Action from '../pages/Action';
import Featured from '../pages/Featured';

export default [
  {
    to: '/',
    name: 'Featured',
    icon: 'star',
    exact: true,
    hasMenu: false,
    restrictedRoute: true,
    enabled: true,
    component: Featured,
  },
  {
    to: '/featured',
    name: 'Featured',
    icon: 'star',
    exact: true,
    hasMenu: true,
    restrictedRoute: true,
    enabled: true,
    component: Featured,
  },

  {
    to: '/artists',
    name: 'Artists',
    icon: 'team',
    exact: true,
    hasMenu: true,
    restrictedRoute: true,
    enabled: true,
    component: Artists,
  },
  {
    to: '/music',
    name: 'Music',
    icon: 'customer-service',
    exact: true,
    hasMenu: true,
    restrictedRoute: true,
    enabled: true,
    component: Music,
  },
  {
    to: '/profile',
    name: 'Profile',
    exact: true,
    hasMenu: false,
    icon: 'user',
    restrictedRoute: true,
    enabled: true,
    component: Profile,
  },
  {
    to: '/dashboard',
    name: 'Dashboard',
    icon: 'desktop',
    exact: true,
    hasMenu: true,
    restrictedRoute: true,
    enabled: true,
    component: Dashboard,
  },
  {
    to: '/signin',
    name: 'Sign In',
    exact: true,
    hasMenu: false,
    restrictedRoute: false,
    enabled: true,
    component: SignIn,
  },
  {
    to: '/forgot-password',
    name: 'Forgot password',
    exact: true,
    hasMenu: false,
    restrictedRoute: false,
    enabled: true,
    component: ForgotPassword,
  },
  {
    to: '/action',
    name: 'Action',
    exact: false,
    hasMenu: false,
    restrictedRoute: false,
    enabled: true,
    component: Action,
  },
];
