import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';

import './assets/style.css';

// Import actions
import { addAuthListener } from './redux/actions/user';

// Import routes
import routeList from './Routes';
import RestrictedRoute from './Routes/RestrictedRoute';
import GuestRoute from './Routes/GuestRoute';

const restrictedRoutes = routeList
  .filter(route => route.restrictedRoute && route.enabled)
  .map(route => (
    <RestrictedRoute
      key={route.to}
      path={route.to}
      component={route.component}
      exact={route.exact}
    />
  ));

const guestRoutes = routeList
  .filter(route => !route.restrictedRoute && route.enabled)
  .map(route => (
    <GuestRoute
      key={route.to}
      path={route.to}
      component={route.component}
      authenticatedRedirect="/profile"
    />
  ));

class AppContent extends Component {
  componentDidMount = () => {
    this.unsubscribeAuthListener = this.props.addAuthListener();
  };

  componentWillUnmount = () => {
    if (this.unsubscribeAuthListener) this.unsubscribeAuthListener();
    this.unsubscribeListeners();
  };

  render() {
    return <Switch>{[...guestRoutes, ...restrictedRoutes]}</Switch>;
  }
}

export default withRouter(
  connect(null, {
    addAuthListener,
  })(AppContent)
);
