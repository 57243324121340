import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';

import routeList from '../../Routes';

const { Sider } = Layout;

class SideBlock extends Component {
  state = {
    collapsed: false,
    location: '/',
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      location: { pathname },
    } = this.props;

    if (pathname)
      if (prevState.location !== pathname)
        this.setState({ location: pathname });
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  renderMenuItems = () => {
    return routeList
      .filter(route => route.hasMenu && route.enabled)
      .map(menuItem => (
        <Menu.Item
          key={menuItem.to}
          onClick={() => {
            const { history } = this.props;
            if (history) history.push(menuItem.to);
          }}
        >
          {menuItem.icon && <Icon type={menuItem.icon} />}
          <span>{menuItem.name}</span>
        </Menu.Item>
      ));
  };

  render() {
    const { location } = this.state;
    const { authenticated } = this.props.auth;
    if (!authenticated) return null;
    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
      >
        <Menu theme="dark" selectedKeys={[location]} mode="inline">
          {this.renderMenuItems()}
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(SideBlock));
