import React from 'react';
import { Header, Container, Segment } from 'semantic-ui-react';
import AddArtist from '../components/Artists/AddArtist';
import ArtistsList from '../components/Artists/ArtistsList';

const Artists = props => {
  return (
    <Container fluid>
      <Header as="h2" content="Artists" subheader="Manage all artists" />
      <Segment basic textAlign="right" size="mini">
        <AddArtist />
      </Segment>
      <Segment basic>
        <ArtistsList />
      </Segment>
    </Container>
  );
};

export default Artists;
