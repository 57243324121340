import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Row, Col, Button, Popconfirm, Badge } from 'antd';
import styled from 'styled-components';

import {
  subscribeToFeatured,
  featuredSelected,
  deleteFeatured,
} from '../../redux/actions/featured';

// Import components
import FeaturedList from './FeaturedList';
import AddFeatured from './AddFeatured';

const ActionButton = styled(Button)`
  padding: 0px;
`;

function FeaturedWrapper() {
  const dispatch = useDispatch();
  const featured = useSelector(store => store.featured.list);

  // Mount
  useEffect(() => {
    return dispatch(subscribeToFeatured());
  }, [dispatch]);

  return (
    <>
      <Row
        type="flex"
        align="middle"
        justify="end"
        style={{ marginBottom: 20 }}
      >
        <AddFeatured />
      </Row>

      {featured &&
        Object.keys(featured).map(key => {
          const item = featured[key];
          return (
            <div key={key}>
              <FeaturedList
                id={item.id}
                header={
                  <Row>
                    <Col>
                      <h2>{item.title}</h2>{' '}
                      <ActionButton
                        type="link"
                        onClick={e => {
                          e.preventDefault();
                          dispatch(featuredSelected({ selected: item }));
                        }}
                      >
                        Manage
                      </ActionButton>{' '}
                      |{' '}
                      <Popconfirm
                        placement="right"
                        title={`Are you sure you want to delete ${item.title}?`}
                        onConfirm={() => {
                          // console.log(key);
                          dispatch(deleteFeatured(key));
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <ActionButton type="link">Remove</ActionButton>
                      </Popconfirm>
                      {!item.enabled && (
                        <Badge style={{ marginLeft: 20 }} count={`Disabled`} />
                      )}
                    </Col>
                  </Row>
                }
                listBuilder={{ id: item.id, list: item.list }}
              />
              <Divider />
            </div>
          );
        })}
    </>
  );
}

export default FeaturedWrapper;
