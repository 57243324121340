import { message } from 'antd';
import { firestore } from '../../api/firebase';
import { SONGS_ADDED } from './types';

export const songsAdded = () => ({
  type: SONGS_ADDED,
});

export const addSongs = album => async dispatch => {
  const { id, songs, artist, coverPhoto } = album;
  const batch = firestore().batch();
  songs.forEach(song => {
    const songRef = firestore().doc(`songs/${song.id}`);
    batch.set(
      songRef,
      {
        albumId: id,
        albumCover: coverPhoto,
        artist,
        ...song,
      },
      { merge: true }
    );
  });
  try {
    await batch.commit();
    return dispatch(songsAdded());
  } catch (error) {
    message.error(error.message);
    throw Error(error.message);
  }
};
