import React, { useState, useEffect, forwardRef } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Index,
  connectAutoComplete,
} from 'react-instantsearch-dom';

import { debounce, startCase } from 'lodash';
import { Search, Label } from 'semantic-ui-react';

import './assets/style.css';

const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_ID || '';
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_KEY || '';

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

const extractData = hit => {
  let value = {};
  // Album
  if (hit.type === 'album')
    value = {
      id: hit.id,
      type: 'album',
      title: hit.title,
      image: hit.coverPhoto,
      song: {},
      songs: hit.songs,
      artists: hit.artists.map(artist => `${artist.name}`).join(', '),
      albumid: hit.id,
      year: hit.year,
    };
  // Artist
  if (hit.name)
    value = {
      id: hit.id,
      type: 'artist',
      title: hit.name,
      image: hit.profilePhoto,
      song: {},
      songs: [],
      artists: [],
      albumid: '',
      year: 0,
    };
  // Song
  if (hit.album)
    value = {
      id: hit.id,
      type: 'song',
      title: hit.title,
      image: hit.coverPhoto,
      song: hit.downloadUrl,
      songs: [],
      artists: hit.artist && hit.artist.name ? hit.artist.name : '',
      albumid: hit.albumId,
      year: hit.year,
    };

  return value;
};

const prepResult = hits => {
  const results = {};

  hits.forEach(category => {
    results[category.index] = {
      name: startCase(category.index),
      results: category.hits.map(hit => extractData(hit)),
    };
  });

  return results;
};

const GlobalAutoComplete = ({ hits, refine, onSelect }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    setIsSearching(false);
    setResults(prepResult(hits));
  }, [hits]);

  const handleSearchChange = (e, { value }) => {
    setIsSearching(true);
    setValue(value);
    refine(value);
  };

  const handleResultSelect = (e, { result }) => {
    setValue('');
    if (onSelect) onSelect(result);
  };

  const categoryRenderer = ({ name }) => <Label as="span" content={name} />;

  const resultRenderer = ({ title }) => <Label content={title} />;

  return (
    <Search
      size="big"
      category
      fluid
      className="global-search"
      noResultsMessage="No result found"
      noResultsDescription="No result found"
      categoryRenderer={categoryRenderer}
      resultRenderer={resultRenderer}
      loading={isSearching}
      onResultSelect={handleResultSelect}
      onSearchChange={debounce(handleSearchChange, 500, { leading: true })}
      results={results}
      value={value}
    />
  );
};

const GlobalSearch = forwardRef(({ onChange }, ref) => {
  return (
    <InstantSearch ref={ref} indexName="albums" searchClient={searchClient}>
      <Index indexName="albums" />
      <Index indexName="artists" />
      {/* <Index indexName="songs" /> */}
      <AutoCompleteSearch onSelect={onChange} />
    </InstantSearch>
  );
});

const AutoCompleteSearch = connectAutoComplete(GlobalAutoComplete);

export default GlobalSearch;
