/**
 * Add your actions types here.
 */

// Auth and User
export const USER_AUTHENTICATING = 'USER_AUTHENTICATING';
export const USER_AUTHENTICATION_FAILED = 'USER_AUTHENTICATION_FAILED';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_SIGNING_OUT = 'USER_SIGNING_OUT';
export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const USER_PROFILE_FETCHED = 'USER_PROFILE_FETCHED';
export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
export const USER_RESET_CODE_VERIFIED = 'USER_RESET_CODE_VERIFIED';
export const USER_PASSWORD_RESET_CONFIRMED = 'USER_PASSWORD_RESET_CONFIRMED';
export const USER_PASSWORD_RESET_SENT = 'USER_PASSWORD_RESET_SENT';

// Artists
export const ARTISTS_FETCHED = 'ARTISTS_FETCHED';
export const ARTIST_SELECTED = 'ARTIST_SELECTED';
export const ARTIST_ADDED = 'ARTIST_ADDED';
export const ARTIST_UPDATED = 'ARTIST_UPDATED';

// Albums
export const ALBUMS_FETCHED = 'ALBUMS_FETCHED';
export const ALBUM_SELECTED = 'ALBUM_SELECTED';
export const ALBUM_ADDED = 'ALBUM_ADDED';
export const ALBUM_UPDATED = 'ALBUM_UPDATED';

// Musics
export const MUSICS_FETCHED = 'MUSICS_FETCHED';
export const MUSIC_SELECTED = 'MUSIC_SELECTED';
export const MUSIC_ADDED = 'MUSIC_ADDED';
export const MUSIC_UPDATED = 'MUSIC_UPDATED';

// Songs
export const SONGS_FETCHED = 'SONGS_FETCHED';
export const SONG_SELECTED = 'SONG_SELECTED';
export const SONG_ADDED = 'SONG_ADDED';
export const SONGS_ADDED = 'SONGS_ADDED';
export const SONG_UPDATED = 'SONG_UPDATED';

// Featured
export const FEATURED_FETCHED = 'FEATURED_FETCHED';
export const FEATURED_SELECTED = 'FEATURED_SELECTED';
export const FEATURED_ADDED = 'FEATURED_ADDED';
export const FEATURED_UPDATED = 'FEATURED_UPDATED';
export const FEATURED_DELETED = 'FEATURED_DELETED';
