import React from 'react';
import SearchSong from '../components/Search/SearchSong';

const Dashboard = props => {
  return (
    <div>
      <SearchSong onChange={selectedSongs => console.log(selectedSongs)} />
    </div>
  );
};

export default Dashboard;
