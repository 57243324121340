import React from 'react';

import UserInfo from '../components/Profile/UseInfo';

const Profile = props => {
  return (
    <div>
      <UserInfo />
    </div>
  );
};

export default Profile;
