import {
  ALBUMS_FETCHED,
  ALBUM_SELECTED,
  ALBUM_ADDED,
  ALBUM_UPDATED,
} from '../actions/types';

const initialState = {
  selected: {},
  list: {},
  last: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALBUMS_FETCHED:
      return {
        ...state,
        list: { ...state.list, ...action.albums.data },
        last: action.albums.lastDocument,
      };
    case ALBUM_SELECTED:
      return { ...state, selected: action.selected };
    case ALBUM_ADDED:
      return {
        ...state,
        list: { ...state.list, [action.album.id]: action.album },
      };
    case ALBUM_UPDATED:
      return {
        ...state,
        list: { ...state.list, [action.album.id]: action.album },
      };

    default:
      return state;
  }
};
